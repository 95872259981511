import Vimeo from '@vimeo/player';
import Player = YT.Player;

export default function initModal() {
  Alpine.data('formModal', (formHandle: '') => ({
    modalOpen: false,
    formRefreshed: false,
    formHandle: formHandle,

    toggle() {
      this.modalOpen = !this.modalOpen;
      if (this.modalOpen) {
        document.documentElement.style.overflow = 'hidden';
        if (!this.formRefreshed) {
          const form = this.$refs.modal.querySelector('form') as HTMLFormElement | null;
          if (form) {
            const csrfInput = form.querySelector('craft-csrf-input');
            if (csrfInput) {
              fetch(`/actions/formie/forms/refresh-tokens/?form=${this.formHandle}`)
                .then(res => res.json())
                .then(result => {
                  csrfInput.outerHTML = result.csrf.input;

                  if (result.captchas && result.captchas.javascript) {
                    const jsCaptcha = result.captchas.javascript;

                    const jsCaptchaInput = form.querySelector(`input[name="${jsCaptcha.sessionKey}"]`) as HTMLInputElement | null;
                    if (jsCaptchaInput) {
                      jsCaptchaInput.value = jsCaptcha.value;
                    }
                  }

                  if (result.captchas && result.captchas.duplicate) {
                    const duplicateCaptcha = result.captchas.duplicate;

                    const duplicateCaptchaInput = form.querySelector(`input[name="${duplicateCaptcha.sessionKey}"]`) as HTMLInputElement | null;
                    if (duplicateCaptchaInput) {
                      duplicateCaptchaInput.value = duplicateCaptcha.value;
                    }
                  }

                  if (form.form && form.form.formTheme) {
                    form.form.formTheme.updateFormHash();
                  }

                  this.formRefreshed = true;
                });
            }
          }
        }
      } else {
        document.documentElement.style.removeProperty('overflow');
      }
    }
  }));

  Alpine.data('videoModal', (provider: string) => ({
    modalOpen: false,

    toggle() {
      this.modalOpen = !this.modalOpen;
      let youtubePlayer: Player | null = null;
      if (provider == 'YouTube' && document.querySelector('script[src="https://www.youtube.com/iframe_api"]') == null) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
      }
      // @ts-ignore
      window.onYouTubeIframeAPIReady = () => {
        youtubePlayer = new YT.Player(this.$refs.iframe);
      }
      if (this.modalOpen) {
        if (provider == 'YouTube' && youtubePlayer !== null) {
          // @ts-ignore
          youtubePlayer.playVideo();
        }
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.removeProperty('overflow');
      }
    }
  }));
}
class BlockCover {
    private block: HTMLElement;
    private $holder!: HTMLElement;
    private ratio: number = 1;
    constructor($block: HTMLElement) {
        this.block = $block;
        if ($block.parentElement) {
            this.$holder = $block.parentElement;
            this.init();
        }
    }

    init() {
        this.resizeHandler();
        this.attachEvents();
    }

    resizeHandler() {
        this.getRatio(this.block);
        this.setBlockPosition();
    }

    getRatio($block: Element) {
        this.ratio = $block.clientWidth / $block.clientHeight;
    }

    setBlockPosition() {
        const styles = this.getDimensions({
            blockRatio: this.ratio,
            maskWidth: this.$holder.clientWidth,
            maskHeight: this.$holder.clientHeight,
        });

        this.block.style.cssText = `width:${styles.width}px;height:${styles.height}px;top:${styles.top}px;left:${styles.left}px;`;
    }

    getDimensions(data: {
        blockRatio: number,
        maskWidth: number,
        maskHeight: number,
    }) {
        let ratio = data.blockRatio,
            slideWidth = data.maskWidth,
            slideHeight = slideWidth / ratio;

        if (slideHeight < data.maskHeight) {
            slideHeight = data.maskHeight;
            slideWidth = slideHeight * ratio;
        }

        return {
            width: slideWidth,
            height: slideHeight,
            top: (data.maskHeight - slideHeight) / 2,
            left: (data.maskWidth - slideWidth) / 2,
        };
    }

    attachEvents() {
        window.addEventListener('resize', () => {
            this.resizeHandler();
        }, true);
    }
}

export default BlockCover;
import Swiper from 'swiper';
import { Autoplay, EffectCreative, EffectFade, Navigation, Pagination, Scrollbar, Thumbs } from 'swiper/modules';
import Vimeo from '@vimeo/player';
import { AutoplayOptions, SwiperOptions } from 'swiper/types';
import Player = YT.Player;
import BlockCover from '../plugins/blockCover';
import initBlockCover from './blockCover';
export default function initSwiper() {
  let youtubeReady = false;

  document.querySelectorAll('.swiper-cards-holder').forEach(function (holder) {
    const mainSwiper = holder.querySelector('.swiper-cards') as HTMLElement | null;
    if (mainSwiper) {
      const navNext = holder.querySelector('.swiper-nav .swiper-button-next') as HTMLElement | null;
      const navPrev = holder.querySelector('.swiper-nav .swiper-button-prev') as HTMLElement | null;
      const slideType = holder.getAttribute('data-slide-type');
      let breakpoints: {
        [width: number]: SwiperOptions;
        [ratio: string]: SwiperOptions;
      } = {
        1024: {
          slidesPerView: 3,
          spaceBetween: 16,
          cssMode: false,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      };
      if (slideType !== null && slideType === 'small') {
        breakpoints = {
          1024: {
            spaceBetween: 24,
            cssMode: false,
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 24,
          },
        };
      }

      new Swiper(mainSwiper, {
        modules: [Scrollbar, Navigation],
        slidesPerView: 'auto',
        grabCursor: true,
        spaceBetween: 12,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
          hide: false,
        },
        navigation: {
          nextEl: navNext,
          prevEl: navPrev,
        },
        breakpoints: breakpoints,
      });
    }
  });

  document.querySelectorAll('.swiper-holder').forEach(function (holder) {
    const mainSwiper = holder.querySelector('.swiper-main') as HTMLElement | null;
    const navSwiperElement = holder.querySelector('.swiper-nav') as HTMLElement | null;
    const navSwiperLine = document.querySelectorAll('.sw-nav-line') as NodeListOf<HTMLElement>;
    let youtubePlayer: Player | null;

    function navLineSetDuration(swiper: Swiper) {
      const autoplay = swiper.params.autoplay as AutoplayOptions;
      document.documentElement.style.setProperty('--active-animation-duration', `${autoplay.delay}ms`);
    }

    function stopAllVimeoVideos() {
      if (mainSwiper) {
        mainSwiper.querySelectorAll('iframe').forEach((iframe) => {
          const players = new Vimeo(iframe);
          players.pause().catch((error) => {
            console.error("Error pausing video:", error);
          });

          players.setCurrentTime(0).catch((error) => {
            console.error("Error resetting video:", error);
          });
        });
      }
    }

    function stopAllVideos() {
      if (mainSwiper) {
        // @ts-ignore
        mainSwiper.querySelectorAll('.slider-video').forEach((sliderHolder: HTMLDivElement) => {
          const provider = sliderHolder.dataset.provider;
          if (provider == 'Vimeo') {
            const iframe = sliderHolder.closest('iframe');
            if (iframe) {
              const player = new Vimeo(iframe);
              player.pause().catch((error) => {
                console.error("Error pausing video:", error);
              });

              player.setCurrentTime(0).catch((error) => {
                console.error("Error resetting video:", error);
              });
            }
          } else if(provider == 'YouTube') {
            if (youtubePlayer !== null && youtubePlayer !== undefined) {
              try {
                youtubePlayer.pauseVideo();
                youtubePlayer.seekTo(0, true);
              } catch {}
            }
            youtubePlayer = null;
          }
        });
      }
    }

    function videoPlayInit(swiper: Swiper) {
      stopAllVideos();

      const indexCurrentSlide = swiper.realIndex;
      const currentSlide = swiper.slides[indexCurrentSlide];
      const video = currentSlide.querySelector('.slider-video') as HTMLDivElement | null;
      if (video) {
        const provider = video.dataset.provider;
        if (provider == 'Vimeo') {
          const vimeoVideo = currentSlide.querySelector('iframe');
          if (vimeoVideo) {
            const player = new Vimeo(vimeoVideo, {
              muted: true,
            });

            let isPlaying = false;

            currentSlide.classList.add('video-active');
            swiper.autoplay.stop();

            player.setVolume(0).catch((error) => {
              console.error('Error muting video:', error);
            });

            player.play().then(function() {
              isPlaying = true;

              player.getDuration().then((duration) => {
                navSwiperLine[indexCurrentSlide].style.setProperty('--active-animation-duration', `${duration}s`);
              })
            }).catch(function(error) {
              switch (error.name) {
                case 'PasswordError':
                  console.log(error.name);
                  break;

                case 'PrivacyError':
                  console.log(error.name);
                  break;

                default:
                  console.log(error.name);
                  break;
              }
            });

            player.on('timeupdate', (data) => {
              if (isPlaying) {
                player.getDuration().then((duration) => {
                  if (duration - data.seconds <= 1) {
                    isPlaying = false;
                    player.unload();
                    swiper.autoplay.start();
                    swiper.slideNext();
                  }
                });
              }
            });
          }
        } else if(provider == 'YouTube') {
          const videoElement = video.firstElementChild as HTMLElement;
          const id = videoElement?.id;
          const videoId = videoElement?.dataset.videoId;
          if (id && videoId) {
            if (youtubePlayer == null && document.querySelector('script[src="https://www.youtube.com/iframe_api"]') == null) {
              const tag = document.createElement('script');
              tag.src = 'https://www.youtube.com/iframe_api';
              const firstScriptTag = document.getElementsByTagName('script')[0];
              firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
            }

            let isPlaying = false;

            if (!youtubeReady) {
              // @ts-ignore
              window.onYouTubeIframeAPIReady = () => {
                youtubeReady = true;
                youtubePlayer = new YT.Player(id, {
                  videoId,
                  width: 1920,
                  height: 1080,
                  events: {
                    'onReady': (event) => {
                      swiper.autoplay.stop();
                      event.target.seekTo(0, true);
                      event.target.setVolume(0);
                      event.target.playVideo();
                      isPlaying = true;
                    },
                    'onStateChange': (event) => {
                      if (event.data == YT.PlayerState.ENDED) {
                        isPlaying = false;
                        event.target.destroy();
                        swiper.autoplay.start();
                        swiper.slideNext();
                        youtubePlayer = null;
                      }
                      if (event.data == YT.PlayerState.PLAYING && isPlaying) {
                        new BlockCover(event.target.getIframe());
                        currentSlide.classList.add('video-active');
                        navSwiperLine[indexCurrentSlide].style.setProperty('--active-animation-duration', `${event.target.getDuration()}s`);
                      }
                    }
                  }
                });
              }
            } else {
              youtubePlayer = new YT.Player(id, {
                videoId,
                width: 1920,
                height: 1080,
                events: {
                  'onReady': (event) => {
                    swiper.autoplay.stop();
                    event.target.seekTo(0, true);
                    event.target.setVolume(0);
                    event.target.playVideo();
                    isPlaying = true;
                  },
                  'onStateChange': (event) => {
                    if (event.data == YT.PlayerState.ENDED) {
                      isPlaying = false;
                      event.target.destroy();
                      swiper.autoplay.start();
                      swiper.slideNext();
                      youtubePlayer = null;
                    }
                    if (event.data == YT.PlayerState.PLAYING && isPlaying) {
                      new BlockCover(event.target.getIframe());
                      currentSlide.classList.add('video-active');
                      navSwiperLine[indexCurrentSlide].style.setProperty('--active-animation-duration', `${event.target.getDuration()}s`);
                    }
                  }
                }
              });
            }
          }
        }
      }
    }

    if (mainSwiper && navSwiperElement) {
      const navSwiper = new Swiper(navSwiperElement, {
        modules: [EffectFade, Pagination],
        spaceBetween: 24,
        slidesPerView: 'auto',
        freeMode: true,
        loop: true,
        effect: 'fade',
        watchSlidesProgress: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
        breakpoints: {
          1024: {
            slidesPerView: 'auto',
            spaceBetween: 0,
            effect: 'slide',
          },
        },
      });

      const mainSwiperSwiper = new Swiper(mainSwiper, {
        modules: [EffectFade, Autoplay, Thumbs],
        effect: 'fade',
        spaceBetween: 24,
        loop: true,
        autoplay: {
          delay: 5000,
        },
        thumbs: {
          swiper: navSwiper,
        },
        on: {
          slideChange: function (swiper) {
            swiper.autoplay.start();
            videoPlayInit(swiper);
          },
        },
      });

      navLineSetDuration(mainSwiperSwiper);
    }
  });

  document.querySelectorAll('.swiper-images').forEach(function (mainSwiper) {
    const navNext = mainSwiper.querySelector('.swiper-nav .swiper-button-next') as HTMLElement | null;
    const navPrev = mainSwiper.querySelector('.swiper-nav .swiper-button-prev') as HTMLElement | null;

    // @ts-ignore
    new Swiper(mainSwiper, {
      modules: [Pagination, Navigation, EffectCreative],
      grabCursor: true,
      spaceBetween: 0,
      slidesPerView: 1,
      loop: true,
      effect: 'creative',
      creativeEffect: {
        prev: {
          shadow: true,
          translate: ["-10%", 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },
      navigation: {
        nextEl: navNext,
        prevEl: navPrev,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      breakpoints: {
        1024: {
          loop: false,
        },
      },
      on: {
        paginationUpdate: function (swiper) {
          const { activeIndex } = swiper;
          mainSwiper.querySelectorAll('.titles span').forEach(function (title, index) {
            if (index !== activeIndex) {
              title.classList.add('hidden');
            } else {
              title.classList.remove('hidden');
            }
          });
        },
      },
    })
  });
}
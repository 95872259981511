document.addEventListener('DOMContentLoaded', () => {
  const el_autohide = document.querySelector('.header-headroom') as HTMLElement;
  const body = document.body;

  if (el_autohide) {
    // add padding-top to body (if necessary)
    if (!body.classList.contains('header-fixed')) {
      setTimeout(() => {
        const navbar_height = el_autohide.offsetHeight;
        document.body.style.paddingTop = navbar_height + 'px';
      }, 300);
    }

    var last_scroll_top = 0;
    window.addEventListener('scroll', function () {
      let scroll_top = window.scrollY;
      if (scroll_top == 0) {
        el_autohide.classList.remove('scrolled-down', 'scrolled-up');
      } else if (scroll_top < last_scroll_top) {
        el_autohide.classList.remove('scrolled-down');
        el_autohide.classList.add('scrolled-up');
      } else {
        el_autohide.classList.remove('scrolled-up');
        el_autohide.classList.add('scrolled-down');
      }
      last_scroll_top = scroll_top;
    });

    const fillPage = document.querySelector('.fill-page') as HTMLElement;

    if (fillPage) {
      if (!body.classList.contains('header-fixed')) {
        setTimeout(() => {
          const navbar_height = el_autohide.offsetHeight;
          fillPage.style.minHeight = window.innerHeight - navbar_height + 'px';
        }, 300);
      }
    }

    window.addEventListener('resize', function () {
      if (!body.classList.contains('header-fixed')) {
        const navbar_height = el_autohide.offsetHeight;
        document.body.style.paddingTop = navbar_height + 'px';
        if (fillPage) {
          fillPage.style.minHeight = window.innerHeight - navbar_height + 'px';
        }
      }
    })

    window.addEventListener('orientationchange', function () {
      if (!body.classList.contains('header-fixed')) {
        const navbar_height = el_autohide.offsetHeight;
        document.body.style.paddingTop = navbar_height + 'px';
        if (fillPage) {
          fillPage.style.minHeight = window.innerHeight - navbar_height + 'px';
        }
      }
    });
  }
});

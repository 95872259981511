export default function initMenu() {
    Alpine.data('menu', () => ({
        menuOpen: false,
        activeNode: null,
        activeLevel: 0,
        width: (window.innerWidth > 0) ? window.innerWidth : screen.width,
        showSearch: false,

        lvl1Node: {
            ['@click'](e: MouseEvent) {
                if (this.$el.childElementCount > 1) {
                    const target = e.target as HTMLElement;
                    if (!target.closest('a')?.classList.contains('group/link-node') && !target.closest('a')?.classList.contains('group/card')) {
                        e.preventDefault();
                        if (target.nodeName == "A") {
                            if (this.menuOpen && this.activeNode == target) {
                                this.menuOpen = false;
                                this.activeNode = null;
                                const lvl1Nodes = document.getElementsByClassName('lvl1-nodes');
                                Array.from(lvl1Nodes).forEach((node) => {
                                    node.classList.remove('active-drop');
                                });
                                const subMenuItems = document.getElementsByClassName('group/sub-menu');
                                Array.from(subMenuItems).forEach((subMenuItem) => {
                                    subMenuItem.classList.remove('active-drop');
                                })
                                this.activeLevel = 0;
                            } else if (!this.menuOpen || this.activeNode !== target) {
                                // if (this.activeLevel == 0) {
                                    this.menuOpen = true;
                                    // @ts-ignore
                                    this.activeNode = target;
                                    const lvl1Nodes = document.getElementsByClassName('lvl1-nodes');
                                    Array.from(lvl1Nodes).forEach((node) => {
                                        if (target.closest('li') == node) {
                                            node.classList.add('active-drop');
                                            const subMenuItems = node.getElementsByClassName('group/sub-menu');
                                            Array.from(subMenuItems).forEach((menuItem, index) => {
                                                if (index === 0) {
                                                    menuItem.classList.add('active-drop');
                                                } else {
                                                    menuItem.classList.remove('active-drop');
                                                }
                                            });
                                        } else {
                                            node.classList.remove('active-drop');
                                        }
                                    });
                                    this.activeLevel = 1;
                                // }
                            }
                        }
                    }
                }
            },
            ['@click.outside'](e: MouseEvent) {
                if (this.width >= 1200) {
                    const target = e.target as HTMLElement;
                    if (this.menuOpen && target.nodeName == "BODY") {
                        this.menuOpen = false;
                        const lvl1Nodes = document.getElementsByClassName('lvl1-nodes');
                        Array.from(lvl1Nodes).forEach((node) => {
                            node.classList.remove('active-drop');
                        });
                        const subMenuItems = document.getElementsByClassName('group/sub-menu');
                        Array.from(subMenuItems).forEach((subMenuItem) => {
                            subMenuItem.classList.remove('active-drop');
                        })
                        this.activeLevel = 0;
                    }
                }
            },
        },

        lvl2Nodes: {
            ['@mouseover'](e: MouseEvent) {
                if (this.width >= 1200) {
                    this.activeLevel = 2;
                    this.$el.parentElement?.classList.add('active-drop')
                    const subMenuItems = document.getElementsByClassName('group/sub-menu');
                    Array.from(subMenuItems).forEach((subMenuItem) => {
                        if (subMenuItem !== this.$el.parentElement) {
                            subMenuItem.classList.remove('active-drop');
                        }
                    });
                }
            },
            ['@click'](e: MouseEvent) {
                const target = e.target as HTMLElement;
                if (!target.closest('a')?.classList.contains('group/link-node')) {
                    // if (target.parentNode == this.$el) {
                        if (this.width < 1200) {
                            e.preventDefault();
                            this.activeLevel = 2;
                            this.$el.parentElement?.classList.add('active-drop')
                            const subMenuItems = document.getElementsByClassName('group/sub-menu');
                            Array.from(subMenuItems).forEach((subMenuItem) => {
                                if (subMenuItem !== this.$el.parentElement) {
                                    subMenuItem.classList.remove('active-drop');
                                }
                            });
                        }
                    // }
                }
            }
        },

        back(newLevel: number) {
            this.activeLevel = newLevel;
            if (newLevel == 0) {
                this.activeNode = null;
                const lvl1Nodes = document.getElementsByClassName('lvl1-nodes');
                Array.from(lvl1Nodes).forEach((node) => {
                    node.classList.remove('active-drop');
                });
                const subMenuItems = document.getElementsByClassName('group/sub-menu');
                Array.from(subMenuItems).forEach((subMenuItem) => {
                    subMenuItem.classList.remove('active-drop');
                });
            } else {
                const subMenuItems = document.getElementsByClassName('group/sub-menu');
                Array.from(subMenuItems).forEach((subMenuItem) => {
                    subMenuItem.classList.remove('active-drop');
                });
            }
        },

        openMenu() {
          this.$refs.navDropHolder.classList.remove('hidden');
          document.body.classList.add('nav-active');
        },

        closeMenu() {
            this.$refs.navDropHolder.classList.add('hidden');
            document.body.classList.remove('nav-active');
            this.menuOpen = false;
            this.activeNode = null;
            this.activeLevel = 0;
            const lvl1Nodes = document.getElementsByClassName('lvl1-nodes');
            Array.from(lvl1Nodes).forEach((node) => {
                node.classList.remove('active-drop');
            });
        },

        toggleSearch() {
            this.showSearch = !this.showSearch;
            if (this.menuOpen) {
                this.closeMenu();
            }
        }
    }));
}
// @ts-nocheck
/* eslint-disable */
/** global: Craft */

var extend = function () {

  // Variables
  var extended = {};
  var deep = false;
  var i = 0;
  var length = arguments.length;

  // Check if a deep merge
  if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
    deep = arguments[0];
    i++;
  }

  // Merge the object into the extended object
  var merge = function (obj) {
    for ( var prop in obj ) {
      if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
        // If deep merge and property is an object, merge properties
        if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
          extended[prop] = extend( true, extended[prop], obj[prop] );
        } else {
          extended[prop] = obj[prop];
        }
      }
    }
  };

  // Loop through each object and conduct a merge
  for ( ; i < length; i++ ) {
    var obj = arguments[i];
    merge(obj);
  }

  return extended;

};

const isPlainObject = value => value?.constructor === Object;

const isEmptyObject = value => Object.keys(value).length === 0 && value.constructor === Object;

const params = value => Object.keys(value).map(function(k) {
  return encodeURIComponent(k) + '=' + encodeURIComponent(value[k])
}).join('&');

Craft = extend(Craft, {
  /**
   * Get a translated message.
   *
   * @param {Object} translates
   * @param {string} message
   * @param {Object} params
   * @return string
   */
  t: function (translates, message, params) {
    if (
      typeof translates[message] !== 'undefined'
    ) {
      message = translates[message];
    }

    if (params) {
      return this.formatMessage(message, params);
    }

    return message;
  },

  /**
   * @param {string} [path]
   * @param {(Object|string)} [params]
   * @param {string} [baseUrl]
   * @returns {string}
   */
  getUrl: function (path, params, baseUrl) {
    if (typeof path !== 'string') {
      path = '';
    }

    // Normalize the params
    let anchor = null;
    if (isPlainObject(params)) {
      if (typeof params['#'] !== 'undefined') {
        anchor = params['#'];
        delete params['#'];
      }
      for (const [key, value] of Object.entries(params)) {
        if (value === undefined || value === null) {
          delete params[key];
        }
      }
    } else if (typeof params === 'string') {
      let anchorPos = params.indexOf('#');
      if (anchorPos !== -1) {
        anchor = params.substring(anchorPos + 1);
        params = params.substring(0, anchorPos);
      }
      params = Object.fromEntries(new URLSearchParams(params).entries());
    } else {
      params = {};
    }

    // Was there already an anchor on the path?
    let anchorPos = path.indexOf('#');
    if (anchorPos !== -1) {
      // Only keep it if the params didn't specify a new anchor
      if (!anchor) {
        anchor = path.substring(anchorPos + 1);
      }
      path = path.substring(0, anchorPos);
    }

    // Were there already any query string params in the path?
    let qsPos = path.indexOf('?');
    if (qsPos !== -1) {
      params = extend(
        Object.fromEntries(
          new URLSearchParams(path.substring(qsPos + 1)).entries()
        ),
        params
      );
      path = path.substring(0, qsPos);
    }

    // Return path if it appears to be an absolute URL.
    if (path.search('://') !== -1 || path[0] === '/') {
      return (
        path +
        (!isEmptyObject(params) ? `?${params(params)}` : '') +
        (anchor ? `#${anchor}` : '')
      );
    }

    path = Craft.trim(path, '/');

    // Put it all together
    let url;

    if (baseUrl) {
      url = baseUrl;

      if (path && Craft.pathParam) {
        // Does baseUrl already contain a path?
        var pathMatch = url.match(
          new RegExp('[&?]' + Craft.escapeRegex(Craft.pathParam) + '=[^&]+')
        );
        if (pathMatch) {
          url = url.replace(
            pathMatch[0],
            Craft.rtrim(pathMatch[0], '/') + '/' + path
          );
          path = '';
        }
      }
    } else {
      url = Craft.baseUrl;
    }

    // Does the base URL already have a query string?
    qsPos = url.indexOf('?');
    if (qsPos !== -1) {
      params = extend(
        Object.fromEntries(
          new URLSearchParams(url.substring(qsPos + 1)).entries()
        ),
        params
      );
      url = url.substring(0, qsPos);
    }

    if (!Craft.omitScriptNameInUrls && path) {
      if (Craft.usePathInfo || !Craft.pathParam) {
        // Make sure that the script name is in the URL
        if (url.search(Craft.scriptName) === -1) {
          url = Craft.rtrim(url, '/') + '/' + Craft.scriptName;
        }
      } else {
        // Move the path into the query string params

        // Is the path param already set?
        if (typeof params[Craft.pathParam] !== 'undefined') {
          let basePath = params[Craft.pathParam].trimEnd();
          path = basePath + (path ? '/' + path : '');
        }

        params[Craft.pathParam] = path;
        path = null;
      }
    }

    if (path) {
      url = Craft.rtrim(url, '/') + '/' + path;
    }

    if (!isEmptyObject(params)) {
      const urlParams = new URLSearchParams(params).toString();
      url += `?${urlParams}`;
    }

    if (anchor) {
      url += `#${anchor}`;
    }

    return url;
  },

  /**
   * Get the site url
   * @param {string} [path]
   * @param {(Object|string)} [params]
   * @returns {string}
   */
  getSiteUrl: function (path, params) {
    return this.getUrl(path, params, Craft.baseSiteUrl);
  },

  /**
   * Returns an action URL.
   *
   * @param {string} action
   * @param {(Object|string)} [params]
   * @returns {string}
   */
  getActionUrl: function (action, params) {
    return Craft.getUrl(action, params, Craft.actionUrl);
  },

  formatMessage: function (pattern, args) {
    let tokens;
    if ((tokens = this._tokenizePattern(pattern)) === false) {
      throw 'Message pattern is invalid.';
    }
    for (let i = 0; i < tokens.length; i++) {
      let token = tokens[i];
      if (typeof token === 'object') {
        if ((tokens[i] = this._parseToken(token, args)) === false) {
          throw 'Message pattern is invalid.';
        }
      }
    }
    return tokens.join('');
  },

  _tokenizePattern: function (pattern) {
    let depth = 1,
      start,
      pos;
    // Get an array of the string characters (factoring in 3+ byte chars)
    const chars = [...pattern];
    if ((start = pos = chars.indexOf('{')) === -1) {
      return [pattern];
    }
    let tokens = [chars.slice(0, pos).join('')];
    while (true) {
      let open = chars.indexOf('{', pos + 1);
      let close = chars.indexOf('}', pos + 1);
      if (open === -1) {
        open = false;
      }
      if (close === -1) {
        close = false;
      }
      if (open === false && close === false) {
        break;
      }
      if (open === false) {
        open = chars.length;
      }
      if (close > open) {
        depth++;
        pos = open;
      } else {
        depth--;
        pos = close;
      }
      if (depth === 0) {
        tokens.push(
          chars
            .slice(start + 1, pos)
            .join('')
            .split(',', 3)
        );
        start = pos + 1;
        tokens.push(chars.slice(start, open).join(''));
        start = open;
      }

      if (depth !== 0 && (open === false || close === false)) {
        break;
      }
    }
    if (depth !== 0) {
      return false;
    }

    return tokens;
  },

  _parseToken: function (token, args) {
    // parsing pattern based on ICU grammar:
    // http://icu-project.org/apiref/icu4c/classMessageFormat.html#details
    const param = token[0].trim();
    if (typeof args[param] === 'undefined') {
      return `{${token.join(',')}}`;
    }
    const arg = args[param];
    const type = typeof token[1] !== 'undefined' ? token[1].trim() : 'none';
    switch (type) {
      case 'number':
        return (() => {
          let format = typeof token[2] !== 'undefined' ? token[2].trim() : null;
          if (format !== null && format !== 'integer') {
            throw `Message format 'number' is only supported for integer values.`;
          }
          let number = Craft.formatNumber(arg);
          let pos;
          if (format === null && (pos = `${arg}`.indexOf('.')) !== -1) {
            number += `.${arg.substring(pos + 1)}`;
          }
          return number;
        })();
      case 'none':
        return arg;
      case 'select':
        return (() => {
          /* http://icu-project.org/apiref/icu4c/classicu_1_1SelectFormat.html
                        selectStyle = (selector '{' message '}')+
                        */
          if (typeof token[2] === 'undefined') {
            return false;
          }
          let select = this._tokenizePattern(token[2]);
          let c = select.length;
          let message = false;
          for (let i = 0; i + 1 < c; i++) {
            if (Array.isArray(select[i]) || !Array.isArray(select[i + 1])) {
              return false;
            }
            let selector = select[i++].trim();
            if (
              (message === false && selector === 'other') ||
              selector == arg
            ) {
              message = select[i].join(',');
            }
          }
          if (message === false) {
            return false;
          }
          return this.formatMessage(message, args);
        })();
      case 'plural':
        return (() => {
          /* http://icu-project.org/apiref/icu4c/classicu_1_1PluralFormat.html
                        pluralStyle = [offsetValue] (selector '{' message '}')+
                        offsetValue = "offset:" number
                        selector = explicitValue | keyword
                        explicitValue = '=' number  // adjacent, no white space in between
                        keyword = [^[[:Pattern_Syntax:][:Pattern_White_Space:]]]+
                        message: see MessageFormat
                        */
          if (typeof token[2] === 'undefined') {
            return false;
          }
          let plural = this._tokenizePattern(token[2]);
          const c = plural.length;
          let message = false;
          let offset = 0;
          for (let i = 0; i + 1 < c; i++) {
            if (
              typeof plural[i] === 'object' ||
              typeof plural[i + 1] !== 'object'
            ) {
              return false;
            }
            let selector = plural[i++].trim();
            let selectorChars = [...selector];

            if (i === 1 && selector.substring(0, 7) === 'offset:') {
              let pos = [...selector.replace(/[\n\r\t]/g, ' ')].indexOf(' ', 7);
              if (pos === -1) {
                throw 'Message pattern is invalid.';
              }
              offset = parseInt(selectorChars.slice(7, pos).join('').trim());
              selector = selectorChars
                .slice(pos + 1, pos + 1 + selectorChars.length)
                .join('')
                .trim();
            }
            if (
              (message === false && selector === 'other') ||
              (selector[0] === '=' &&
                parseInt(
                  selectorChars.slice(1, 1 + selectorChars.length).join('')
                ) === arg) ||
              (selector === 'one' && arg - offset === 1)
            ) {
              message = (
                typeof plural[i] === 'string' ? [plural[i]] : plural[i]
              )
                .map((p) => {
                  return p.replace('#', arg - offset);
                })
                .join(',');
            }
          }
          if (message === false) {
            return false;
          }
          return this.formatMessage(message, args);
        })();
      default:
        throw `Message format '${type}' is not supported.`;
    }
  },

  /**
   * Escapes special regular expression characters.
   *
   * @param {string} str
   * @returns {string}
   */
  escapeRegex: function (str) {
    // h/t https://stackoverflow.com/a/9310752
    return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  },

  /**
   * Formats a number.
   *
   * @param {string} number
   */
  formatNumber: function (number) {
    return Number(number);
  },

  /**
   * Formats a number into a currency
   *
   * @param {number} number
   */
  formatCurrency: function (number) {
    return new Intl.NumberFormat(Craft.locale, {
      style: 'currency',
      currency: Craft.currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);
  },

  /**
   * Trim characters off of the beginning of a string.
   *
   * @param {string} str
   * @param {string|object|undefined} [chars] The characters to trim off. Defaults to a space if left blank.
   * @return string
   */
  ltrim: function (str, chars) {
    if (!str) {
      return str;
    }
    if (typeof chars === 'undefined') {
      chars = ' \t\n\r\0\x0B';
    }
    var re = new RegExp('^[' + chars + ']+');
    return str.replace(re, '');
  },

  /**
   * Trim characters off of the end of a string.
   *
   * @param {string} str
   * @param {string|object|undefined} [chars] The characters to trim off. Defaults to a space if left blank.
   * @return string
   */
  rtrim: function (str, chars) {
    let usedChars = chars;
    if (!str) {
      return str;
    }
    if (typeof usedChars === 'undefined') {
      usedChars = ' \t\n\r\0\x0B';
    }
    const re = new RegExp(`[${usedChars}]+$`);
    return str.replace(re, '');
  },

  /**
   * Trim characters off of the beginning and end of a string.
   *
   * @param {string} str
   * The characters to trim off. Defaults to a space if left blank.
   * @param {string|object|undefined} [chars]
   * @return string
   */
  trim: (str, chars) => Craft.rtrim(Craft.ltrim(str, chars), chars),
});
/* eslint-enable */

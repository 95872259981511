import Alpine from 'alpinejs';
import './plugins/CookieConsent';
import './plugins/Craft';
import './plugins/headroom.ts';
import initMenu from './components/menu';
import initJarallax from './components/jarallax';
import initSwiper from './components/swiper';
import initBlockCover from './components/blockCover';
import initModal from './components/modal';
import initStickySidebar from './components/StickySidebar';
import initNewsletter from './components/newsletter';

window.Alpine = Alpine;

document.addEventListener('alpine:init', () => {
  initMenu();
  initModal();
  initJarallax();
  initSwiper();
  initBlockCover();
  initStickySidebar();
  initNewsletter();
});

Alpine.start();
htmx.on('htmx:afterSwap', () => {
  initStickySidebar();
});

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    // eslint-disable-next-line no-console
    console.log('HMR');
  });
}

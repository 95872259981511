export default function initNewsletter() {
  Alpine.data('newsletter', () => ({
    submitting: false,

    async handleSubmit(event: Event) {
      event.preventDefault();
      this.submitting = true;

      const form = event.target as HTMLFormElement;
      const formData = new FormData(form);

      try {
        if (document.querySelector('script[src*="recaptcha/enterprise.js"]')) {
          // @ts-ignore
          const token = await grecaptcha.enterprise.execute(form.dataset.sitekey, { action: 'newsletter_signup' });
          formData.append('g-recaptcha-response', token);
        }

        const response = await fetch(form.action, {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json'
          }
        });
        const result = await response.json();
        
        if (result.success) {
          const successInput = document.getElementById('notification-success') as HTMLInputElement | null;
          if (successInput) {
            successInput.value = form.dataset.successMessage || '';
          }
          form.reset();
        } else {
          const errorInput = document.getElementById('notification-error') as HTMLInputElement | null;
          if (errorInput) {
            errorInput.value = result.error || form.dataset.errorMessage || '';
          }
        }
        htmx.trigger('#notification', 'refresh');
      } catch (error) {
        const errorInput = document.getElementById('notification-error') as HTMLInputElement | null;
        if (errorInput) {
          errorInput.value = form.dataset.errorMessage || '';
        }
        htmx.trigger('#notification', 'refresh');
      }
      this.submitting = false;
    }
  }));
}
import StickySidebar from '../plugins/stickySidebarPlugin';

export default function initStickySidebar() {
  const header = document.getElementById('header');

  if (header && window.innerWidth > 1024) {
    document.querySelectorAll('#cart-sidebar').forEach(fixedSidebar => {
      const content = fixedSidebar.querySelector('.cart-summary-inner');

      const stickySidebar = new StickySidebar(fixedSidebar, {
        topSpacing: (header ? header.offsetHeight : 0) + 64,
        bottomSpacing: 0,
        containerSelector: '#cart',
      });

      const resizeObserver = new ResizeObserver(() => {
        if (window.innerWidth > 1024) {
          stickySidebar.updateSticky()
        } else {
          stickySidebar.destroy();
        }
      });

      resizeObserver.observe(document.body);

      if (content) {
        resizeObserver.observe(content);
      }
    })
  }
}